<template>
  <div>
    <el-dialog :title="title" :before-close="handleClose" :visible.sync="dialog" width="60%">
      <el-form :model="addEditForm" label-width="100px" ref="addEditForm">
        <el-row :gutter="24">
          <el-col :md="6" :sm="8">
            <el-form-item label="编码" prop="productCode">
              <el-input
                placeholder="请输入编码"
                v-model="addEditForm.productCode"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="8">
            <el-form-item label="名字" prop="name">
              <el-input
                placeholder="请输入名字"
                v-model="addEditForm.name"
                prop="name"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="8">
            <el-form-item label="英文名字" prop="englishName">
              <el-input
                placeholder="请输入英文名字"
                v-model="addEditForm.englishName"
                prop="englishName"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="8">
            <el-form-item label="简称" prop="summary">
              <el-input
                placeholder="请输入简称"
                v-model="addEditForm.summary"
                prop="summary"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :md="6" :sm="8">
            <el-form-item label="类型：硬件字段" prop="type">
              <el-input
                placeholder="类型"
                v-model="addEditForm.type"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="8">
            <el-form-item label="单位" prop="unit">
              <el-input
                placeholder="单位"
                v-model="addEditForm.unit"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :md="6" :sm="8">
            <el-form-item label="是否为一级菜单" prop="isMenu" v-if="this.attribute==2">
              <el-checkbox
                v-model="addEditForm.isMenu"
                :checked="addEditForm.isMenu===1? true:false"
                :true-label="1"
                :false-label="0"
              >是</el-checkbox>
            </el-form-item>
          </el-col>
          <!-- <el-col :md="6" :sm="8">
            <el-form-item label="图标" prop="icon">
              <el-input
                disabled
                placeholder="请输入"
                v-model="addEditForm.icon"
                prop="icon"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="8">
            <el-form-item label="图片" prop="iamges">
              <el-input
                disabled
                placeholder="请输入"
                v-model="addEditForm.iamges"
                prop="iamges"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col>-->
          <el-col :md="6" :sm="8">
            <el-form-item label="标题" prop="titile">
              <el-input
                placeholder="请输入"
                v-model="addEditForm.titile"
                prop="titile"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="8">
            <el-form-item label="地址" prop="address">
              <el-input
                placeholder="请输入地址"
                v-model="addEditForm.address"
                prop="address"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :md="6" :sm="8">
            <el-form-item label="行业" prop="industry">
              <el-input
                placeholder="请输入行业"
                v-model="addEditForm.industry"
                prop="industry"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="8">
            <el-form-item label="范围" prop="scope">
              <el-input
                placeholder="请输入范围"
                v-model="addEditForm.scope"
                prop="scope"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="8">
            <el-form-item label="状态" prop="status">
              <el-radio v-model="addEditForm.status" label="1">正常</el-radio>
              <el-radio v-model="addEditForm.status" label="2">停用</el-radio>
            </el-form-item>
          </el-col>
          <!-- <el-col :md="6" :sm="8">
            <el-form-item label="价格" prop="price">
              <el-input-number
                placeholder="请输入"
                v-model="addEditForm.price"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col> -->
        </el-row>
        <el-row :gutter="24">
          <el-col :md="24" :sm="24">
            <el-form-item label="介绍" prop="content">
              <el-input
                placeholder="请输入介绍"
                v-model="addEditForm.content"
                prop="content"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :md="24" :sm="24">
            <el-form-item label="备注" prop="remark">
              <el-input
                placeholder="请输入备注"
                v-model="addEditForm.remark"
                prop="remark"
                clearable
                @clear="handleQuery()"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelForm('addEditForm')">取 消</el-button>
        <!-- @click="$refs.drawer.closeDrawer()" -->
        <el-button
          type="primary"
          @click="addFormButton()"
          :loading="loading"
        >{{ loading ? '提交中 ...' : '确 定' }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  selectproductByIdApi,
  deleteproductByIdApi,
  editproductApi,
  addproductApi,
  listproductApi
} from "@/api/system/product/product.js";
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      editShow: false,
      title: null,
      addEditForm: {},
      timer: null,
      ids: null,
      attribute: 1,
      productCode: null,
      caType: 1
    };
  },
  methods: {
    show(id, type, attribute, code) {
      this.dialog = true;
      if (type == 2) {
        this.ids = id;
        this.selectByIdButton(id);
        this.editShow = true;
        this.title = "编辑";
      }
      if (type == 1) {
        this.title = "新增";
      }
      this.attribute = attribute;
      this.productCode = code;
      this.caType = type;
    },
    handleClose(done) {
      if (this.loading) {
        return;
      }
      this.$confirm("关闭")
        .then(_ => {
          // this.loading = true;
          this.cancelForm();
          this.dialog = false;
          // this.timer = setTimeout(() => {
          //   done();
          //   // 动画关闭需要一定的时间
          //   // setTimeout(() => {
          // this.loading = false;
          //   // }, 400);
          // }, 2000);
        })
        .catch(_ => {});
    },
    cancelForm(formName) {
      this.loading = false;
      this.dialog = false;
      this.editShow = false;
      this.$refs["addEditForm"].resetFields();
      this.$parent.selectDataListButton();
    },
    addFormButton() {
      this.addEditForm.attribute = this.attribute;
      if (this.productCode != null) {
        this.addEditForm.productCode = this.productCode;
      }
      console.log(this.caType);
      if ((this.caType = 1)) {
        this.addEditForm.id;
        addproductApi(this.addEditForm).then(res => {
          this.cancelForm();
        });
      }
      if (this.caType == 2) {
        editproductApi(this.addEditForm).then(res => {
          this.cancelForm();
        });
      }
    },
    //查询详情
    selectByIdButton() {
      selectproductByIdApi(this.ids).then(res => {
        this.addEditForm = res.result;
      });
    },
    addDialogClosed() {
      // 对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
      this.$refs["addEditForm"].resetFields();
    }
  }
};
</script>
<style scoped>
</style>



